// smooth layout
&.layout-advanced-smooth {

	.lead-section {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.info-nav {
		display: flex;
		justify-content: space-between;
	}

	.actions {
		display: flex;
		align-items: center;

		&-item {
			&:not(:last-child) {
				border-right: 1px solid $primary;
				padding-right: 0.75rem;
				margin-right: 0.75rem;

				@include media-breakpoint-up(md) {
					padding-right: 1rem;
					margin-right: 1rem;
				}
			}

			a {
				text-decoration: none;
			}

			&-icon {
				@include media-breakpoint-up(md) {
					margin-right: 10px;
				}
			}

			&-title {
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
		}
	}

	.info {
		margin-top: 1.5rem;

		.list {
			display: flex;
			flex-flow: row wrap;
			gap: 5px;
			margin-bottom: 2.5rem;
			padding-left: 0 !important;

			&-item {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;
				border-radius: 99px;
				font-size: 13px;
				padding: 2px 4px;
				cursor: pointer;
				transition: var(--w3-booking-transition-duration, .3s);
				border: 1px solid var(--w3-booking-result-item-iconlist-item-border-color, #cecece);
				color: var(--w3-booking-result-item-iconlist-item-color, $primary);
				background-color: var(--w3-booking-result-item-iconlist-item-background, $white);
				line-height: normal;
				height: 25px;
				min-width: 25px;

				&.list-item-with-title {
					padding-left: 10px;
					padding-right: 10px;

					.icon {
						margin-right: 2px;
					}
				}

				&:hover {
					border-color: var(--w3-booking-result-item-iconlist-item-border-color-hover, $primary);
					color: var(--w3-booking-result-item-iconlist-item-color-hover, $white);
					background-color: var(--w3-booking-result-item-iconlist-item-background-hover, $primary);

					.hover-label {
						bottom: calc(100% + 1em);
						z-index: 1;
						visibility: visible;
						opacity: 1;
					}
				}

				.icon {
					+.title {
						padding: 0;
					}
				}

				.title {
					padding: 0 5px;
				}

				.hover-label {
					position: absolute;
					bottom: 100%;
					left: 50%;
					width: max-content;
					transform: translateX(-50%);
					background-color: var(--w3-booking-result-item-iconlist-label-background, $black);
					color: var(--w3-booking-result-item-iconlist-label-color, #FFF);
					padding: 4px 15px;
					border-radius: 4px;
					filter: drop-shadow(2px 2px 2px rgba($black, 0.4));
					z-index: -1;
					visibility: hidden;
					opacity: 0;
					transition: var(--w3-booking-transition-duration, .3s);
					line-height: 1.5;

					@include media-breakpoint-down(lg) {
						max-width: 100px;
						padding: 4px 10px;
						text-overflow: ellipsis;
						overflow-x: clip;
					}

					&::after {
						content: "";
						display: block;
						position: absolute;
						bottom: -8px;
						left: 50%;
						transform: translateX(-50%);
						border-left: 10px solid transparent;
						border-top: 10px solid var(--w3-booking-result-item-iconlist-label-background, $black);
						border-right: 10px solid transparent;
					}
				}
			}
		}
	}

	.content-section {
		margin-top: 0;
		padding-top: 0;

		.container-three-columns {
			.container-holder {
				> .column {
					&:has(.filters-fullscreen) {
						z-index: 999999;
					}
					&.one,
					&.three {
						@extend .pr-sm-3;
						@extend .pr-lg-3;

						@include media-breakpoint-up(md) {
							@include make-col(12);
						}

						@include media-breakpoint-up(lg) {
							@include make-col(8);
						}
					}

					&.two {
						@extend .pl-sm-3;
						@extend .pl-lg-3;

						@include media-breakpoint-up(md) {
							@include make-col(12);
						}

						@include media-breakpoint-up(lg) {
							@include make-col(4);
						}
					}
				}
			}
		}

		.container-one-column {
			.column {
				@include media-breakpoint-up(lg) {
					@include make-col(8);
				}
			}
		}

		ul, ol {
			&.custom-list {
				list-style: none !important;
				padding: 0 !important;
			}
		}

		h1, h2, h3, h4, h5, h6 {
			font-size: 1.5rem;
			color: $black;
		}
	}

	.item-label {
		position: absolute;
		top: 10px;
		left: 25px;
		background-color: var(--w3-booking-result-item-label-bg, $white);
		color: var(--w3-booking-result-item-label-color, $primary);
		padding: 10px;
		border-radius: 4px;
		font-size: 14px;
		z-index: 1;

		&::after {
			content: "";
			display: inline-block;
			position: absolute;
			left: 14px;
			bottom: -12px;
			z-index: 1;
			border-left: 14px solid var(--w3-booking-result-item-label-bg, $white);
			border-bottom: 14px solid transparent;
			border-right: 14px solid transparent;
		}
	}

	.widget-title {
		margin-bottom: 1.5rem;
	}
}

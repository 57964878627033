// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 4px;
			background: $primary;
			box-shadow: 3px 3px 6px rgba($black, 0.15);
			color: #fff;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 30px;
				padding-right: 30px;
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}
	}
}

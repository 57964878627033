// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {}

	// logo
	.logo {
		width: 75px;
		margin: 10px 10px 10px 0;

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {}

		@include media-breakpoint-up(xl) {}
	}

	// site-switcher
	.site-switcher {}
}
